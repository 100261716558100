/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import "react-jinke-music-player/assets/index.css";
import { getSongsByIds } from "./services/db/songs.service";
import {
  addLikeDb,
  addToPlaylistDb,
  getPlaylist,
  removeToPlaylistDb,
} from "./services/db/playlists.service";
import SongsList from "./components/SongsList";
import { PlayerSong } from "./models/Song";
import {
  Button,
  Chip,
  Grid,
  IconButton,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import { useWeb3React } from "@web3-react/core";
import useAuth from "./hooks/useAuth";

function Playlist() {
  const [isLoading, setIsLoading] = useState(false);
  const [playlistInfo, setPlaylistInfo] = useState<{
    name: string;
    id?: string;
    likedUsers?: string[];
    totalLikes?: number;
  }>({ name: "-- Playlist" });

  const [userPlaylist, setUserPlaylist] = useState<PlayerSong[]>();
  const { id } = useParams();
  const navigate = useNavigate();
  const [isInvalidId, setIsInvalidId] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<boolean | string>();

  const { account } = useWeb3React();
  const { login } = useAuth();

  const fetchPlaylist = async (address: string) => {
    setIsLoading(true);
    const playlist = await getPlaylist(address);
    if (playlist) {
      setIsLiked(playlist.likedUsers?.includes(address) || false);
      setPlaylistInfo({
        name: playlist?.name,
        id: playlist.id,
      });
      if (playlist.songs) {
        const availableSongIds = playlist.songs
          // .filter((s) => s.isAvailable)
          .map((s) => s.address);
        if (availableSongIds.length) {
          const playlistSongs = await getSongsByIds(availableSongIds);
          setUserPlaylist(
            playlistSongs.map((s, i) => ({
              id: s.id as string,
              idx: i,
              name: s.name,
              musicSrc: s.audioFileUrl,
              cover: s.artworkUrl,
              // singer: "",
            }))
          );
        } else {
          setUserPlaylist([]);
        }
      }
    } else {
      setIsInvalidId(true);
    }
    setIsLoading(false);

    if (!account) login();
  };

  useEffect(() => {
    if (id) {
      fetchPlaylist(id);
    }
  }, [id]);

  if (isLoading) {
    return (
      <Box
        style={{
          minHeight: "100vh",
          background:
            "linear-gradient(0deg, rgba(20,20,61,0.9920561974789917) 0%, rgba(22,22,42,1) 77%)",
        }}
        display="flex"
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Typography align="center">Finding the playlist...</Typography>
      </Box>
    );
  }

  // if (isInvalidId) {
  //   return (
  //     <Box
  //       style={{
  //         minHeight: "100vh",
  //         background:
  //           "linear-gradient(0deg, rgba(20,20,61,0.9920561974789917) 0%, rgba(22,22,42,1) 77%)",
  //       }}
  //     >
  //       <Box p={2}>
  //         <Typography variant="h4">NUSIC</Typography>
  //       </Box>
  //     </Box>
  //   );
  // }
  const addToPlaylist = async (id: string) => {
    if (account) {
      try {
        await addToPlaylistDb(account, {
          address: id,
        });
      } catch (e) {
        setSnackbarMessage(
          "Unable to Add to the Playlist, please try again later"
        );
      }
      // await fetchPlaylist(account);
      setSnackbarMessage("Successfully added to the playlist");
    } else setSnackbarMessage("Please connect your account to continue");
  };
  const removeToPlaylist = async (id: string) => {
    if (account) {
      try {
        await removeToPlaylistDb(account, {
          address: id,
        });
      } catch (e) {
        setSnackbarMessage(
          "Unable to Add to the Playlist, please try again later"
        );
      }
      await fetchPlaylist(account);
      setSnackbarMessage("Successfully added to the playlist");
    } else setSnackbarMessage("Please connect your account to continue");
  };

  return (
    <Box
      style={{
        minHeight: "100vh",
        background:
          "linear-gradient(0deg, rgba(20,20,61,0.9920561974789917) 0%, rgba(22,22,42,1) 77%)",
      }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{playlistInfo.name}</title>
        <meta
          property="og:title"
          content={`Checkout the ${playlistInfo.name}`}
          key="title"
        />
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
      </Helmet>
      <Box p={2}>
        <Grid container alignItems={"center"} rowSpacing={4}>
          <Grid item xs={10}>
            <Typography
              variant="h4"
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/");
              }}
            >
              NUSIC Player
            </Typography>
          </Grid>
          <Grid item xs={6} md={2}>
            <Box display={"flex"} justifyContent="end">
              {account ? (
                <Tooltip title={account} placement={"bottom-start"}>
                  <Chip
                    clickable
                    label={`${account.slice(0, 6)}...${account.slice(
                      account.length - 4
                    )}`}
                    // size="small"
                    color="info"
                    variant="outlined"
                    onClick={() => navigate("/dashboard")}
                  />
                </Tooltip>
              ) : (
                <Button
                  variant="outlined"
                  color="info"
                  onClick={(e) => {
                    e.stopPropagation();
                    login();
                  }}
                >
                  connect
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Grid container>
        <Grid item xs={0} md={3}></Grid>
        <Grid item xs={12} md={6}>
          {isInvalidId ? (
            <Box display="flex" alignItems={"center"} justifyContent={"center"}>
              <Typography align="center">
                Unable to find any playlist for this ID.
              </Typography>
            </Box>
          ) : (
            <Box
              sx={{ height: "100vh", overflowY: "auto" }}
              p={2}
              maxHeight={"80vh"}
              mb={9}
            >
              <Box
                display={"flex"}
                justifyContent="space-between"
                alignItems={"center"}
                mb={4}
                flexWrap="wrap"
                gap={2}
                sx={{
                  background:
                    "linear-gradient(90deg, rgba(58,180,164,1) 0%, rgba(253,235,29,1) 48%, rgba(149,252,69,1) 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  fontWeight: "bold",
                }}
              >
                <Typography variant="h5">{playlistInfo.name}</Typography>
                <IconButton
                  onClick={() => {
                    if (!account) {
                      setSnackbarMessage("Please connect your wallet");
                      return;
                    }
                    setIsLiked(true);
                    if (id && !isLiked) addLikeDb(id, account);
                  }}
                >
                  {isLiked ? (
                    <FavoriteOutlinedIcon color="error" />
                  ) : (
                    <FavoriteBorderOutlinedIcon color="secondary" />
                  )}
                </IconButton>
              </Box>
              {userPlaylist && userPlaylist.length && (
                <SongsList
                  isLoading={isLoading}
                  songs={userPlaylist}
                  addToPlaylist={addToPlaylist}
                  showAddToPlaylist={id !== account}
                  removeToPlaylist={removeToPlaylist}
                />
              )}
              {(userPlaylist?.length === 0 || !userPlaylist) && !isLoading && (
                <Box>
                  <Typography>No songs available in the playlist</Typography>
                </Box>
              )}
              {userPlaylist && userPlaylist?.length === 0 && (
                <Typography align="center">Playlist is Empty</Typography>
              )}
            </Box>
          )}
        </Grid>
        <Grid item></Grid>
      </Grid>
      <Snackbar
        open={!!snackbarMessage}
        onClose={() => {
          setSnackbarMessage(false);
        }}
        message={snackbarMessage}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      />
    </Box>
  );
}

export default Playlist;
