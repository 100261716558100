import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createTheme, responsiveFontSizes, ThemeProvider } from "@mui/material";
import WebFont from "webfontloader";
import { Web3ReactProvider } from "@web3-react/core";
import { ethers } from "ethers";
import { Routes, Route, HashRouter as Router } from "react-router-dom";
import Playlist from "./Playlist";
import Dashboard from "./Dashboard";

WebFont.load({
  google: {
    families: ["BenchNine", "Space Mono", "Tenor Sans"],
  },
});

const themeSettings = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#573FC8",
      light: "#000000",
    },
    secondary: {
      main: "#ffffff",
    },
    info: {
      main: "#A794FF",
    },
    warning: {
      main: "#ffcc00",
    },
    background: { paper: "#16162A" },
  },
  typography: {
    button: {
      fontFamily: '"Space Mono" , sans-serif',
    },
    // h4: {
    //   fontFamily: '"BenchNine"',
    // },
    allVariants: {
      color: "#ffffff",
    },
    fontFamily: `"Space Mono" , sans-serif`,
  },
});
const theme = responsiveFontSizes(themeSettings);

const POLLING_INTERVAL = 12000;
export const getLibrary = (provider: any): ethers.providers.Web3Provider => {
  const library = new ethers.providers.Web3Provider(provider);
  library.pollingInterval = POLLING_INTERVAL;
  return library;
};
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ThemeProvider theme={theme}>
    <Web3ReactProvider getLibrary={getLibrary}>
      <Router>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/playlist/:id" element={<Playlist />} />
        </Routes>
      </Router>
    </Web3ReactProvider>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
