import { LoadingButton } from "@mui/lab";
import {
  Box,
  TextField,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
} from "@mui/material";
import axios from "axios";
import { ChangeEvent, useEffect, useState } from "react";
import { MoralisNftData } from "../../models/MoralisNFT";
import { NftDetails, Song } from "../../models/Song";
import NFTPreview from "../NFTPreview";

type Props = {
  open: boolean;
  onClose: () => void;
  onSaveSongPlaylist: (nft: Song) => Promise<void>;
};

function ListNFT({ open, onClose, onSaveSongPlaylist }: Props) {
  //   const [tokenAddress, setTokenAddress] = useState<string>();
  //   const [tokenId, setTokenId] = useState<string>();
  const [marketPlaceLink, setMarketPlaceLink] = useState<string>();
  const [errorText, setErrorText] = useState<string>();
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [nftDetails, setNftDetails] = useState<Song>();

  const resetComponentState = () => {
    setMarketPlaceLink(undefined);
    setErrorText(undefined);
    setIsError(false);
    setIsLoading(false);
    setNftDetails(undefined);
  };

  const onMarketPlaceLinkChange = async (e: ChangeEvent<HTMLInputElement>) => {
    resetComponentState();
    setMarketPlaceLink(e.target.value);
    //https://opensea.io/assets/ethereum/0xb48fc73160b1e3c77709cd275c588a049c7266b2/10
    if (e.target.value.includes("opensea")) {
      try {
        const [chain, address, token] = e.target.value
          .split("opensea.io/assets/")[1]
          .split("/");
        if (chain === "ethereum" || chain === "matic") {
          setIsError(false);
          setIsLoading(true);
          const _chainMoralisName = chain === "ethereum" ? "eth" : "polygon";
          const options = {
            method: "GET",
            url: `https://deep-index.moralis.io/api/v2/nft/${address}/${token}`,
            params: {
              chain: _chainMoralisName,
              format: "decimal",
              normalizeMetadata: "true",
            },
            headers: {
              accept: "application/json",
              "X-API-Key": process.env.REACT_APP_MORALIS_KEY,
            },
          };
          const response = await axios.request(options);
          const nftData = response.data as MoralisNftData;
          if (nftData.token_uri) {
            const metadata = await onFetchNftPreview(nftData.token_uri);
            if (metadata) {
              if (!metadata.audioFileUrl.length) {
                setIsError(true);
                setErrorText(
                  `${metadata.name} doesn't have music in the metadata, please try entering a Music NFT link`
                );
                setIsLoading(false);
                return;
              }
              setNftDetails({
                address: nftData.token_address,
                artworkUrl: metadata.artworkUrl,
                audioFileUrl: metadata.audioFileUrl,
                name: metadata.name,
                tokenId: nftData.token_id,
                format: metadata.format,
                tokenUri: nftData.token_uri,
                chain: _chainMoralisName,
              });
            } else {
              setIsError(true);
              setErrorText(
                "Unable to retrieve the NFT metadata, please try again later"
              );
            }
          }
        } else {
          setIsError(true);
          setErrorText("Only Ethereum & Polygon NFTs supported at the moment");
        }
        setIsLoading(false);
      } catch (e) {
        setIsError(true);
        setErrorText("Unable to retrieve the NFT, please try again later");
        setIsLoading(false);
      }
    } else {
      setIsError(true);
      setErrorText("Only Opensea NFT links are supported at the moment");
      setIsLoading(false);
    }
  };

  const onFetchNftPreview = async (
    tokenUri: string
  ): Promise<NftDetails | null> => {
    const response = await axios.post(`${process.env.REACT_APP_SERVER}/nft/`, {
      tokenUri,
    });
    if (response) {
      const data = response.data as NftDetails;
      return data;
    } else {
      return null;
    }
  };

  const onSave = async () => {
    if (nftDetails && nftDetails.address) {
      setIsLoading(true);
      await onSaveSongPlaylist(nftDetails);
      setNftDetails(undefined);
      setIsLoading(false);
      onClose();
    }
  };

  useEffect(() => {
    if (!open) {
      resetComponentState();
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={() => {
        resetComponentState();
        onClose();
      }}
    >
      <DialogTitle>List NFT</DialogTitle>
      <DialogContent>
        <Box>
          <Box p={2}>
            {/* <Box display={"flex"}>
              <TextField
                value={tokenAddress}
                label="NFT Address"
                fullWidth
              ></TextField>
              <TextField
                value={tokenId}
                label="Token ID"
                sx={{ width: "150px" }}
              ></TextField>
            </Box>
            <Box m={4}>
              <Typography>OR</Typography>
            </Box> */}
            <Box>
              <TextField
                value={marketPlaceLink || ""}
                onChange={onMarketPlaceLinkChange}
                label="Opensea NFT Url"
                fullWidth
                helperText={
                  isError
                    ? errorText
                    : "Ex: https://opensea.io/assets/chain_name/nft_address/token_id"
                }
                error={isError}
              ></TextField>
            </Box>
          </Box>
          <Divider />
          {isLoading && <LinearProgress color="secondary" />}
          {nftDetails && <NFTPreview nft={nftDetails} />}
          {/* {nftDetails && (
            <Box p={2}>
              <Box display={"flex"} alignItems="center">
                <Typography>Preview</Typography>
                <Typography variant="caption" sx={{ ml: 2 }}>
                (only for Opensea)
              </Typography>
              </Box>
              <Box
                display={"flex"}
                gap={2}
                justifyContent="center"
                p={2}
                mt={2}
                flexWrap="wrap"
                alignItems="center"
              >
                <img
                  src={nftDetails?.imagePreviewUrl}
                  alt=""
                  width={100}
                  height={100}
                  style={{ borderRadius: "6px" }}
                />
                <Box display={"flex"} flexDirection="column" gap={2}>
                  <Typography variant="h6">
                    {nftDetails?.openseaName}
                  </Typography>
                  <Box mt={1}>
                    <audio controls src={nftDetails?.animationUrl}></audio>
                  </Box>
                </Box>
                <Typography>{nftDetails?.description}</Typography>
              </Box>
            </Box>
          )} */}
        </Box>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          onClick={() => {
            resetComponentState();
            onClose();
          }}
          color="info"
        >
          Cancel
        </LoadingButton>
        <LoadingButton
          disabled={!nftDetails?.address}
          loading={isLoading}
          variant="contained"
          onClick={onSave}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default ListNFT;

// setNftDetails(response.data as MoralisNftData);
// const response = await axios.get(
//   `https://api.opensea.io/asset/${address}/${token}`
// );
// //animation_url
// //image_preview_url
// //name
// //description
// const {
//   animation_url,
//   image_preview_url,
//   name,
//   description,
//   asset_contract,
//   collection,
// } = response.data;
// const collectinName = collection.name;
// // .includes("-")
// //   ? collection.name
// //   : `${collection.name} - ${asset_contract?.name}`;
// setNftDetails({
//   chain,
//   address,
//   tokenId: token,
//   animationUrl: animation_url,
//   imagePreviewUrl: image_preview_url,
//   name: asset_contract?.name,
//   openseaName: name,
//   description,
//   collectionName: collectinName,
// });
