/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography } from "@mui/material";
import { Song } from "../../models/Song";

type Props = {
  nft: Song;
  // isLoading: boolean;
  // isError: boolean;
};

const NFTPreview = ({ nft }: Props) => {
  // if (isError) {
  //   return (
  //     <Box>
  //       <Typography variant="h6" sx={{ mb: 2 }} align="center" color={"error"}>
  //         {nft?.name}
  //       </Typography>
  //       <Typography align="center">
  //         does not have Music in the Metadata or does not follow https or ipfs
  //         or arweave formats
  //       </Typography>
  //       <Typography sx={{ mt: 4 }}>
  //         If this is a music NFT, DM us with {nft.address} #{nft.tokenId}
  //       </Typography>
  //     </Box>
  //   );
  // }

  return (
    <Box>
      <Box
        display={"flex"}
        gap={2}
        justifyContent="center"
        p={2}
        mt={2}
        flexWrap="wrap"
        alignItems="center"
      >
        {nft.artworkUrl && (
          // <img
          //   src={artworkUrl}
          //   alt=""
          //   width={100}
          //   height={100}
          //   style={{ borderRadius: "6px" }}
          // />
          <Box
            style={{
              borderRadius: "6px",
              backgroundImage: `url(${nft.artworkUrl})`,
              backgroundSize: "100%",
            }}
            width={100}
            height={100}
          ></Box>
        )}
        <Box display={"flex"} flexDirection="column" gap={2}>
          <Typography variant="h6">{nft.name}</Typography>
          {nft.audioFileUrl && (
            <Box mt={1}>
              <audio controls src={nft.audioFileUrl} autoPlay></audio>
            </Box>
          )}
        </Box>
        {/* <Typography>{nftDetails?.description}</Typography> */}
      </Box>
    </Box>
  );
};

export default NFTPreview;
