import {
  Box,
  Skeleton,
  IconButton,
  Typography,
  List,
  ListItemButton,
  Popover,
  CircularProgress,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PauseIcon from "@mui/icons-material/Pause";
import PlayCircleOutlinedIcon from "@mui/icons-material/PlayCircleOutlined";
import ReactJkMusicPlayer, {
  ReactJkMusicPlayerInstance,
} from "react-jinke-music-player";
import { useState } from "react";
import { PlayerSong } from "../../models/Song";
import { incrementStreamCount } from "../../services/db/songs.service";

type Props = {
  isLoading: boolean;
  songs: PlayerSong[];
  addToPlaylist?: (id: string) => void;
  removeToPlaylist?: (id: string) => void;
  showAddToPlaylist?: boolean;
};

const SongsList = ({
  isLoading,
  songs,
  addToPlaylist,
  showAddToPlaylist,
  removeToPlaylist,
}: Props) => {
  const [playIndex, setPlayIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(true);
  const [audioIns, setAudioIns] = useState<ReactJkMusicPlayerInstance>();
  const [showPopover, setShowPopover] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [timer, setTimer] = useState<NodeJS.Timeout>();
  const [prevStreamId, setPrevStreamId] = useState<string>();
  const [selectedSong, setSelectedSong] = useState<PlayerSong>();
  const [isPlaylistActionLoading, setIsPlaylistAcitonLoading] = useState(false);

  const resetTimer = (isPlay: boolean = false, playId?: string) => {
    if (timer) {
      clearTimeout(timer);
    }
    if (isPlay) {
      setTimer(
        setTimeout(() => {
          // Increase Stream
          setPrevStreamId(playId);
          if (playId) incrementStreamCount(playId);
          console.log(`Streamed: ${songs[playIndex].name}`);
        }, 10_000)
      );
    }
  };

  const onAddToPlaylist = async (songAddress: string) => {
    if (addToPlaylist) {
      setIsPlaylistAcitonLoading(true);
      await addToPlaylist(songAddress);
      setIsPlaylistAcitonLoading(false);
      setShowPopover(false);
    }
  };

  const onRemoveToPlaylist = async (songAddress: string) => {
    if (removeToPlaylist) {
      setIsPlaylistAcitonLoading(true);
      await removeToPlaylist(songAddress);
      setIsPlaylistAcitonLoading(false);
      setShowPopover(false);
    }
  };
  return (
    <Box display={"flex"} flexDirection="column">
      {isLoading &&
        new Array(15)
          .fill("")
          .map((x, i) => (
            <Skeleton key={i} height="61px" sx={{ mx: 4 }} animation="wave" />
          ))}
      {songs.map((song, i) => (
        <Box
          boxShadow={
            playIndex === song.idx
              ? "rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px"
              : ""
          }
          key={song.idx}
          p={1}
          display="flex"
          alignItems={"center"}
          sx={{
            borderRadius: "6px",
            ":hover": {
              background: "rgba(0,0,0,0.3)",
            },
          }}
          justifyContent="space-between"
          width="100%"
          onClick={() => {
            if (song.idx === playIndex) {
              return;
            }
            if (audioIns?.updatePlayIndex) audioIns.updatePlayIndex(song.idx);
            // setPlayIndex(i);
          }}
        >
          <Box display="flex" alignItems={"center"} gap={2}>
            <Box display="flex" alignItems={"center"} gap={2}>
              <Box position={"relative"} mr={1} width="24px">
                {playIndex === song.idx && (
                  <Box
                    sx={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      borderRadius: "4px",
                    }}
                    display="flex"
                  >
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        if (isPaused) audioIns?.play();
                        else audioIns?.pause();
                      }}
                      sx={{
                        background: "black",
                        borderRadius: "4px",
                        ":hover": {
                          background: "black",
                        },
                      }}
                    >
                      {isPaused ? (
                        <PlayCircleOutlinedIcon
                          sx={{
                            background: "rgb(0,0,0)",
                            borderRadius: "50%",
                          }}
                        />
                      ) : (
                        <PauseIcon
                          sx={{
                            background: "rgb(0,0,0)",
                            borderRadius: "50%",
                          }}
                        />
                      )}
                    </IconButton>
                  </Box>
                )}
                <Typography align="right" sx={{ color: "#c4c4c4" }}>
                  {i + 1}
                </Typography>
              </Box>
              <img
                width="40px"
                src={song.cover}
                style={{ borderRadius: "4px" }}
                alt=""
              />
            </Box>
            <Box>
              <Typography textTransform={"capitalize"}>{song.name}</Typography>
              {/* <Typography variant="caption" color="#c4c4c4">
                {song.openseaName}
              </Typography> */}
            </Box>
          </Box>
          <Box>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setAnchorEl(e.target as any);
                setSelectedSong(song);
                setShowPopover(true);
              }}
            >
              <MoreVertIcon
                sx={{
                  color: "rgba(255,255,255,0.3)",
                }}
              />
            </IconButton>
          </Box>
        </Box>
      ))}
      <ReactJkMusicPlayer
        // responsive={false}
        audioLists={songs}
        mode="full"
        onAudioPause={() => {
          resetTimer();
          setIsPaused(true);
        }}
        onAudioPlay={(audioInfo) => {
          if (audioInfo.id === prevStreamId) {
            resetTimer();
          } else {
            resetTimer(true, audioInfo.id);
          }
          setIsPaused(false);
        }}
        // onAudioPlayTrackChange={}
        getAudioInstance={(audio) => setAudioIns(audio)}
        onPlayIndexChange={(idx) => setPlayIndex(idx)}
        autoPlay={false}
        volumeFade={{ fadeIn: 1000, fadeOut: 500 }}
        defaultVolume={0.5}
        // drag={false}
        defaultPosition={{ right: "10%", bottom: "5%" }}
        showReload={false}
        showLyric={false}
        showDestroy={false}
        showDownload={false}
        showThemeSwitch={false}
        // mobileMediaQuery='(min-width: 1024px)'
      />
      <Popover
        open={showPopover}
        onClose={() => setShowPopover(false)}
        anchorEl={anchorEl}
        sx={{ mt: 6 }}
      >
        <List>
          <ListItemButton
            onClick={() => {
              if (selectedSong && addToPlaylist)
                onAddToPlaylist(selectedSong.id);
            }}
            disabled={!showAddToPlaylist || isPlaylistActionLoading}
          >
            {isPlaylistActionLoading ? (
              <CircularProgress size={"18px"} color="info" />
            ) : (
              <Typography>Add to Playlist</Typography>
            )}
          </ListItemButton>
          {!showAddToPlaylist && (
            <ListItemButton
              onClick={() => {
                if (selectedSong && removeToPlaylist)
                  onRemoveToPlaylist(selectedSong.id);
              }}
            >
              {isPlaylistActionLoading ? (
                <CircularProgress size={"18px"} color="info" />
              ) : (
                <Typography>Remove from Playlist</Typography>
              )}
            </ListItemButton>
          )}
          {/* <ListItemButton>
            <Typography>Add to Queue</Typography>
          </ListItemButton> */}
          {/* <ListItemButton>
            <Typography>View Nft</Typography>
          </ListItemButton> */}
        </List>
      </Popover>
    </Box>
  );
};

export default SongsList;
