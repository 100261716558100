/* eslint-disable react-hooks/exhaustive-deps */

import { LoadingButton } from "@mui/lab";
import {
  Box,
  Grid,
  Typography,
  Tooltip,
  Chip,
  Button,
  Card,
  CardContent,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Snackbar,
  LinearProgress,
  Link,
  Divider,
} from "@mui/material";
import { useWeb3React } from "@web3-react/core";
import axios from "axios";
// import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NFTPreview from "./components/NFTPreview";
import useAuth from "./hooks/useAuth";
import { MoralisNftData } from "./models/MoralisNFT";
import { Playlist } from "./models/Playlist";
import { NftDetails, Song } from "./models/Song";
import { addToPlaylistDb, getPlaylist } from "./services/db/playlists.service";
import { addSongToDb } from "./services/db/songs.service";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
// import { uploadFromUrl } from "./services/storage";

type Props = {};

// const startMoralis = async () => {
//   //   await Moralis.start({
//   //     apiKey: process.env.REACT_APP_MORALIS_KEY,
//   //   });
// };

const Dashboard = (props: Props) => {
  const { account } = useWeb3React();
  const { login } = useAuth();

  const navigate = useNavigate();
  const [lastPage, setLastPage] = useState(0);
  const [pageDetails, setPageDetails] = useState<{
    [key: number]: { cursor: string; records: MoralisNftData[] };
  }>({});
  const [loadingRecords, setLoadingRecords] = useState(false);
  const [isInsStarted, setIsInsStarted] = useState(false);
  const [nfts, setNfts] = useState<MoralisNftData[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [isLoading, setIsLoading] = useState(false);

  const [nftSong, setNftSong] = useState<Song>();
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<boolean | string>(
    false
  );
  const [playlistInfo, setPlaylistInfo] = useState<Playlist>();

  const fetchNfts = async (pageId: number) => {
    if (pageDetails[pageId]) {
      setNfts(pageDetails[pageId].records);
      setCurrentPage(pageId);
      return;
    }
    if (!isInsStarted) {
      // await startMoralis();
      setIsInsStarted(true);
    }
    //   const chain = EvmChain.ETHEREUM;
    //   const response = await Moralis.EvmApi.nft.getWalletNFTs({
    //     address: account as string,
    //     chain,
    //     cursor: pageDetails[pageId].cursor,
    //     limit: 30,
    //     normalizeMetadata: true,
    //     format: "decimal",
    //   });
    setLoadingRecords(true);
    const options = {
      method: "GET",
      url: `https://deep-index.moralis.io/api/v2/${account}/nft`,
      params: {
        chain: "eth",
        format: "decimal",
        limit: "30",
        cursor: pageDetails[pageId - 1]?.cursor,
        normalizeMetadata: "true",
      },
      headers: {
        accept: "application/json",
        "X-API-Key": process.env.REACT_APP_MORALIS_KEY,
      },
    };
    const response = await axios.request(options);
    const json = response.data;
    if (json.result?.length) {
      const filteredRecords = (json.result as MoralisNftData[]).filter(
        (x) =>
          x.contract_type === "ERC721" &&
          x.token_uri &&
          (x.normalized_metadata.name
            ? x.normalized_metadata.animation_url
            : true)
      );
      if (json.total <= json.page * json.page_size) {
        setLastPage(json.page);
      }
      const newPageDetails = {
        [json.page]: {
          cursor: json.cursor,
          records: filteredRecords,
        },
      };

      setCurrentPage(Number(json.page));
      setPageDetails({ ...pageDetails, ...newPageDetails });
      setNfts(filteredRecords);
      setLoadingRecords(false);
    } else {
      setLoadingRecords(false);
      setNfts([]);
    }
  };
  const fetchPlaylist = async (address: string) => {
    const playlist = await getPlaylist(address);
    if (playlist) {
      setPlaylistInfo(playlist);
    }
  };

  useEffect(() => {
    if (account) {
      fetchPlaylist(account);
    } else {
      login();
    }
  }, [account]);

  const addToPlaylist = async (id: string) => {
    if (account) {
      try {
        await addToPlaylistDb(account, {
          address: id,
        });
        setSnackbarMessage("Successfully added to the playlist");
        setNftSong(undefined);
      } catch (e) {
        setSnackbarMessage(
          "Unable to Add to the Playlist, please try again later"
        );
      }
    } else setSnackbarMessage("Please connect your account to continue");
  };

  const onNftSelect = async (nftData: MoralisNftData) => {
    if (nftData.token_uri) {
      setIsPreviewLoading(true);
      try {
        const metadata = await onFetchNftPreview(nftData.token_uri);
        if (metadata) {
          if (!metadata.audioFileUrl.length) {
            setSnackbarMessage(
              `${
                metadata.name || nftData.name
              } doesn't have music in the metadata, please try entering a Music NFT link`
            );
            setIsPreviewLoading(false);
            return;
          }
          setNftSong({
            address: nftData.token_address,
            artworkUrl: metadata.artworkUrl,
            audioFileUrl: metadata.audioFileUrl,
            name: metadata.name,
            tokenId: nftData.token_id,
            format: metadata.format,
            tokenUri: nftData.token_uri,
          });
        } else {
          setSnackbarMessage(
            "Unable to retrieve the NFT metadata, please try again later"
          );
        }
      } catch (e: any) {
        setSnackbarMessage(
          "Unable to retrieve the NFT metadata, please try again later"
        );
      } finally {
        setIsPreviewLoading(false);
      }
    }
  };

  const onFetchNftPreview = async (
    tokenUri: string
  ): Promise<NftDetails | null> => {
    const response = await axios.post(`${process.env.REACT_APP_SERVER}/nft/`, {
      tokenUri,
    });
    if (response) {
      const data = response.data as NftDetails;
      return data;
    } else {
      return null;
    }
  };

  const onSaveInSongs = async () => {
    if (nftSong) {
      try {
        if (account) {
          setIsLoading(true);
          await addSongToDb(nftSong);
          await addToPlaylist(`${nftSong.address}-${nftSong.tokenId}`);
        } else {
          setSnackbarMessage("Please connect your account and try again");
        }
      } catch (e) {
        setSnackbarMessage("Successfully added to the playlist");
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Box
      style={{
        minHeight: "100vh",
        background:
          "linear-gradient(0deg, rgba(20,20,61,0.9920561974789917) 0%, rgba(22,22,42,1) 77%)",
      }}
    >
      <Box p={2}>
        <Grid container alignItems={"center"} rowSpacing={4}>
          <Grid item xs={8} md={5}>
            <Typography
              variant="h4"
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/");
              }}
            >
              NUSIC Player
            </Typography>
          </Grid>
          <Grid item xs={0} md={4}>
            {/* <TextField
              label="Search"
              fullWidth
              onChange={(e) => {
                const _new = songsDataSource.filter(
                  (s) =>
                    s.name
                      ?.toString()
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase()) ||
                    s.singer
                      ?.toString()
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase())
                );
                setSongs(_new);
              }}
            ></TextField> */}
          </Grid>
          <Grid item xs={4} md={3}>
            <Box display={"flex"} justifyContent="end" alignItems={"center"}>
              {account ? (
                <Tooltip title={account} placement={"bottom-start"}>
                  <Chip
                    label={`${account.slice(0, 6)}...${account.slice(
                      account.length - 4
                    )}`}
                    // size="small"
                    color="info"
                    variant="outlined"
                  />
                </Tooltip>
              ) : (
                <Button
                  variant="outlined"
                  color="info"
                  onClick={(e) => {
                    e.stopPropagation();
                    login();
                  }}
                >
                  connect
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
      {account ? (
        <Box mt="2rem">
          {/* <Box m={4} width={{ md: "50%" }}>
            <Typography>Your Playlists</Typography>
            <List>
              <ListItemButton
                href={`https://player.nusic.fm/#/playlist/${account}`}
              >
                <Typography>1. {playlistInfo.name}</Typography>
              </ListItemButton>
            </List>
          </Box> */}
          <Grid container>
            <Grid item md={3}></Grid>
            <Grid item xs={12} md={6}>
              <Box m={4}>
                <Typography variant="h5">Dashboard</Typography>
              </Box>
              <Divider />
              <Box m={4} display="flex" alignItems={"center"} gap={4}>
                <Typography>Playlist: </Typography>
                {playlistInfo ? (
                  <Box
                    display={"flex"}
                    justifyContent="space-between"
                    alignItems={"center"}
                    width="100%"
                  >
                    <Link
                      href={`//player.nusic.fm/#/playlist/${playlistInfo.id}`}
                      target="_blank"
                      variant="h5"
                      sx={{
                        background:
                          "linear-gradient(90deg, rgba(58,180,164,1) 0%, rgba(253,235,29,1) 48%, rgba(149,252,69,1) 100%)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                        fontWeight: "bold",
                      }}
                    >
                      {playlistInfo.name}
                    </Link>
                    <Box display={"flex"} alignItems={"center"} gap={0.5}>
                      <Typography variant="h6">
                        {playlistInfo.totalLikes}{" "}
                      </Typography>
                      <FavoriteOutlinedIcon
                        color="error"
                        sx={{ width: "18px" }}
                      />
                    </Box>
                  </Box>
                ) : (
                  <Typography>--</Typography>
                )}
              </Box>
              <Box m={4}>
                <LoadingButton
                  disabled={isInsStarted}
                  variant="contained"
                  onClick={() => {
                    fetchNfts(currentPage);
                  }}
                >
                  Add Music NFTs from your wallet
                </LoadingButton>
              </Box>
              {nfts.length ? (
                <Box>
                  <Box m={4}>
                    <Typography variant="h5">
                      Select the music NFT from your NFT collections:
                    </Typography>
                    <Box my={1}>
                      {(loadingRecords || isPreviewLoading) && (
                        <LinearProgress />
                      )}
                    </Box>
                  </Box>
                  <Box
                    m={4}
                    mb={0}
                    display={"flex"}
                    justifyContent="space-between"
                  >
                    <LoadingButton
                      variant="outlined"
                      size="small"
                      color="info"
                      disabled={currentPage === 1}
                      onClick={() => {
                        fetchNfts(currentPage - 1);
                      }}
                    >
                      Previous
                    </LoadingButton>
                    {/* <Box>
                      <Typography>Total: {total}</Typography>
                    </Box> */}
                    <LoadingButton
                      variant="outlined"
                      size="small"
                      color="info"
                      onClick={() => {
                        fetchNfts(currentPage + 1);
                      }}
                      disabled={lastPage === currentPage}
                    >
                      Next
                    </LoadingButton>
                  </Box>
                  <Box p={4} display={"flex"} gap={2} flexWrap="wrap">
                    {nfts.map((nft) => (
                      <Card
                        key={nft.token_address + nft.token_id}
                        sx={{
                          "#isnft": {
                            visibility: "hidden",
                          },
                          "&:hover": {
                            "#isnft": {
                              visibility: "visible",
                            },
                          },
                        }}
                      >
                        <CardContent>
                          <Typography>{nft.name}</Typography>
                          {/* <Typography>{nft.symbol}</Typography> */}
                          <Box
                            id="isnft"
                            display="flex"
                            justifyContent={"space-between"}
                            alignItems="center"
                            gap={4}
                            mt={4}
                          >
                            {/* <Typography>is it a Music NFT?</Typography> */}
                            <Button
                              disabled={isPreviewLoading}
                              variant="outlined"
                              color="info"
                              onClick={() => {
                                onNftSelect(nft);
                              }}
                            >
                              Select
                            </Button>
                          </Box>
                        </CardContent>
                      </Card>
                      //   <Box>
                      //     <Box>
                      //       <Typography>{nft.name}</Typography>
                      //       <Typography>{nft.symbol}</Typography>
                      //     </Box>
                      //     <Box></Box>
                      //   </Box>
                    ))}
                  </Box>
                </Box>
              ) : (
                isInsStarted &&
                (loadingRecords ? (
                  <LinearProgress />
                ) : (
                  <Typography align="center">
                    No NFT Collections found for this wallet address, try again
                    with a different wallet
                  </Typography>
                ))
              )}
            </Grid>
            <Grid item md={3}></Grid>
          </Grid>
        </Box>
      ) : (
        <Box>
          <Typography align="center">Your wallet is not connected</Typography>
        </Box>
      )}

      <Dialog
        open={!!nftSong}
        onClose={() => {
          if (isLoading) return;
          setNftSong(undefined);
          setIsPreviewLoading(false);
        }}
      >
        <DialogTitle>Preview</DialogTitle>
        <DialogContent>{nftSong && <NFTPreview nft={nftSong} />}</DialogContent>
        <DialogActions>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            onClick={onSaveInSongs}
          >
            Start Streaming
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={!!snackbarMessage}
        onClose={() => {
          setSnackbarMessage(false);
        }}
        message={snackbarMessage}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      />
    </Box>
  );
};

export default Dashboard;
// 0x5b93ff82faaf241c15997ea3975419dddd8362c5;
